import { Component, SimpleChanges } from '@angular/core';
import { FacturationService } from '../../services/facturation/facturation.service';
import { SharedService } from '../../services/shared/shared.service';
import { SharedModule } from '../../modules/shared/shared.module';
import { Facturation } from '../../interfaces/Facturation';
import { BreadcrumbComponent } from "../../common/breadcrumb/breadcrumb.component";


@Component({
    selector: 'app-facturation',
    standalone: true,
    templateUrl: './facturation.component.html',
    styleUrl: './facturation.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})

export class FacturationComponent 
{
  date:Date = new Date()
  title:string = "Facturation"
  pageSize:number = 7
  pageIndex:number = 1
  idService!:number
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    "num_facture":"N°de facture",
    "montant_ht":"Montant HT",
    "date_facturation":"Date de facturation",
    "date_reglement":"Date de règlement",
    "telecharger":"Télécharger",
    "exporter":"Exporter"
  }
  data_body_table:Facturation[] = []
  isDataReady:boolean = false

  constructor
  (
    private facturationService: FacturationService,
    private sharedService: SharedService
  ) 
  {
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getDataFacturation()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getDataFacturation()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getDataFacturation()
  }

  async createPagination(pageSize:number)
  {
    (await this.sharedService.getInfoPagination("facturation", this.idService)).subscribe(data => {
      this.totalResult = data[0]?.total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getDataFacturation(search:string="")
  {
    this.facturationService.getData(this.pageIndex, this.pageSize, this.idService ,search).subscribe((data:Facturation[]) => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}
